class RescheduleAuctionComponent {
  constructor(selector) {
    $(selector).on('click', event => {
      if (event.currentTarget.checked === true) {
        $('#auction_postponement_comment').removeAttr('disabled');
        $('#postponement-comments').children().removeClass('text-muted');

        $('#postponement-date').removeAttr('disabled');
        $('#postponement-auction-date').children().removeClass('text-muted');
      } else {
        $('#auction_postponement_comment').attr('disabled', 'disabled');
        $('#postponement-comments').children().addClass('text-muted');

        $('#postponement-date').attr('disabled', 'disabled');
        $('#postponement-auction-date').children().addClass('text-muted');
      }
    });
  }
}

export default RescheduleAuctionComponent;
