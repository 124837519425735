// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require('@rails/ujs').start();
require('@rails/activestorage').start();
require('channels');
require('trix');
require('@rails/actiontext');

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.

const images = require.context('../images', true);
// eslint-disable-next-line no-unused-vars
const imagePath = name => images(name, true);

import 'bootstrap';
import '../stylesheets/application.scss';

// Initialize Sentry Error Reporting
//
// Import all your application's JS after this section because we need Sentry
// to be initialized **before** we import any of our actual JS so that Sentry
// can report errors from it.
//
import * as Sentry from '@sentry/browser';
Sentry.init({
  dsn: process.env.SENTRY_DSN,
  environment: process.env.SENTRY_ENV || process.env.RAILS_ENV
});

// Uncomment this Sentry by sending an exception every time the page loads.
// Obviously this is a very noisy activity and we do have limits on Sentry so
// you should never do this on a deployed environment.
//
// Sentry.captureException(new Error('Away-team to Enterprise, two to beam directly to sick bay...'));

// Import all your application's JS here
// import { someFunc } from '../javascript/funcs.js';
require.context('../images', true);
require.context('../documents', true);

// Uncomment this to make jQuery available as its usual globals for easier JS
// console debugging:
// window.$ = window.jQuery = $;

import FileInputComponent from '../jquery/components/file-input';
import VerifyFormComponent from '../jquery/components/verify-form';
import RescheduleAuctionComponent from '../jquery/components/reschedule-auction';

$(document).ready(() => {
  new VerifyFormComponent(
    '#verify-radio-1,#verify-radio-2,#verify-radio-3,#verify-radio-4'
  );
  new FileInputComponent('body');
  new RescheduleAuctionComponent('#auction_reschedule');
});
