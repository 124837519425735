class FileInputComponent {
  constructor(selector) {
    let $form = $(selector);
    $("input[type='file']", $form).on('change', function (e) {
      $(this)
        .siblings('label')
        .text(e.target.value.replace(/.*[/\\]/, '') || 'Choose file');
    });
    $("input[type='reset']", $form).on('click', () => {
      $("input[type='file']", $form).siblings('label').text('Choose file');
    });
  }
}

export default FileInputComponent;
