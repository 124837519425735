class VerifyFormComponent {
  constructor(selector) {
    $(selector).on('click', () => {
      let $form = $('#verify_form');
      $form.children().trigger('reset');
      $("input[type='file']", $form).siblings('label').text('Choose file');
    });
  }
}

export default VerifyFormComponent;
